import type { FC } from 'react';
import React, { memo, useCallback, useContext } from 'react';
import { defineMessages } from 'react-intl-next';

import { useAnalyticsEvents } from '@atlaskit/analytics-next/useAnalyticsEvents';

import {
	SSRMouseEventWrapper,
	SSR_NAV_CUSTOM_HOMEPAGE_BUTTON_METRIC,
} from '@confluence/browser-metrics';
import { createLazyCallbackHook } from '@confluence/loadable/entry-points/lazy-callback';
import { WIKI, CONTEXT_PATH } from '@confluence/named-routes';
import { RoutesContext } from '@confluence/route-manager/entry-points/RoutesContext';

import { PrimaryItem } from './PrimaryItem';

const i18n = defineMessages({
	CustomHomeLink: {
		id: 'app-navigation.custom.home.link',
		description: 'link to the custom Home page',
		defaultMessage: 'Custom Home Page',
	},
});

const useLazyClickAnalytics = createLazyCallbackHook(
	async () =>
		(await import(/* webpackChunkName: "loadable-analyticsCallbacks" */ './analyticsCallbacks'))
			.fireCustomHomepageClickedAnalytics,
);

type CustomHomeItemProps = {
	testId: string;
	href: string;
	label?: string;
};

export const CustomHomepageItem: FC<CustomHomeItemProps> = memo(({ testId, href, label }) => {
	const { location, match } = useContext(RoutesContext);

	const { createAnalyticsEvent } = useAnalyticsEvents();
	const fireClickAnalytics = useLazyClickAnalytics(createAnalyticsEvent, match?.name);
	const handleClick = useCallback(() => {
		void fireClickAnalytics();
	}, [fireClickAnalytics]);

	const isHighlighted =
		!!location &&
		location.pathname === `${CONTEXT_PATH}${href}` &&
		location.search.includes('mode=global');

	return (
		<SSRMouseEventWrapper metricName={SSR_NAV_CUSTOM_HOMEPAGE_BUTTON_METRIC}>
			<PrimaryItem
				testId={testId}
				href={`${CONTEXT_PATH}${href}?mode=global`}
				label={label}
				i18n={i18n.CustomHomeLink}
				namedRoute={WIKI}
				onClick={handleClick}
				isHighlighted={isHighlighted}
			/>
		</SSRMouseEventWrapper>
	);
});
